<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('personalInfo.label.personal_info') }}</span>
      </template>

      <account-setting-general
        v-if="userData"
        :user-data="userData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('personalInfo.label.change_password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import dataRequester from '@/mixins/utils/dataRequester'
import useJwt from '@/auth/jwt/useJwt'
import userService from "@/mixins/utils/axios/user.service";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword
  },
  mixins: [dataRequester],
  data () {
    return {
      options: {},
      userData: {}
    }
  },
  created () {
    const self = this
    this.runApi(userService.getUserDetail(), function (result) {
      self.userData = result.data
    })
  }
}
</script>
