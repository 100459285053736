<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <b-form
        class="mt-2"
        @submit.prevent="changePassword"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="$t('personalInfo.label.old_password')"
              label-for="change-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="change-old-password"
                    v-model="passwordValueOld"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="change-old-password"
                    placeholder="············"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              :label="$t('personalInfo.label.new_password')"
              label-for="change-new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required|min:8|password"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="change-new-password"
                    v-model="newPasswordValue"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="change-new-password"
                    placeholder="············"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="change-new-pw_confirm"
              :label="$t('personalInfo.label.retype_password')"
            >
              <validation-provider
                #default="{ errors }"
                name="pw_confirm"
                rules="required|confirmed:password"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="change-new-pw_confirm"
                    v-model="retypePassword"
                    type="password"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="change-new-pw_confirm"
                    placeholder="············"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12" class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid || isPasswordChanging"
            >
              <b-spinner
                v-if="isPasswordChanging"
                class="mr-50"
                small
              />
              <feather-icon
                v-else
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('personalInfo.button.password_change') }}</span>
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BSpinner, BInputGroupPrepend, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import dataRequester from '@/mixins/utils/dataRequester'
import md5 from 'md5'
import userService from "@/mixins/utils/axios/user.service";

export default {
  components: {
    BSpinner,
    BInputGroupPrepend,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  mixins: [dataRequester],
  data () {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      isPasswordChanging: false
    }
  },
  computed: {

  },
  methods: {
    changePassword () {
      const self = this
      this.isPasswordChanging = true
      this.runApi(userService.changePassword({
        old_password: md5(self.passwordValueOld),
        new_password: md5(self.newPasswordValue)
      }), function (result) {
        self.$swal({
          title: self.$t('axios.title.success'),
          text: self.$te(`axios.success.${result.message}`) ? self.$t(`axios.success.${result.message}`) : result.message,
          icon: 'success',
          confirmButtonText: 'Đăng nhập ngay',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        }).then(() => {
          self.$router.push({ name:'auth-login' })
        })
      }, null, function () {
        self.isPasswordChanging = false
      })
    }
  }
}
</script>
