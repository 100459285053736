<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="require('@/assets/images/avatars/bug-hug.png')"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <h3 class="mb-0">
          {{ userData['user_name'] }}
        </h3>
        <div class="d-flex align-items-center mr-2">
          <b-avatar
            variant="light-success"
            rounded
          >
            <feather-icon
              icon="DollarSignIcon"
              size="18"
            />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ currencyFormat(userData['balance']) }}
            </h5>
            <small>{{ $t('personalInfo.label.balance') }}</small>
          </div>
        </div>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <b-form
        class="mt-2"
        @submit.prevent="updatePersonalInfo"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('personalInfo.label.full_name')"
              label-for="personal-info-full-name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('personalInfo.label.full_name')"
                rules="required|max:255"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TypeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="personal-info-full-name"
                    v-model="userData['full_name']"
                    name="personal-info-full-name"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('personalInfo.label.email')"
              label-for="personal-info-email"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('personalInfo.label.email')"
                rules="required|email|max:255"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="personal-info-email"
                    v-model="userData['email']"
                    name="personal-info-email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('personalInfo.placeholder.email')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('personalInfo.label.facebook_url')"
              label-for="personal-info-facebook-url"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('personalInfo.label.facebook_url')"
                rules="required|url|max:255"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FacebookIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="personal-info-facebook-url"
                    v-model="userData['facebook_url']"
                    name="personal-info-facebook-url"
                    type="url"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('personalInfo.placeholder.facebook_url')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('personalInfo.label.facebook_name')"
              label-for="personal-info-facebook-name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('personalInfo.label.facebook_name')"
                rules="required|max:255"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TypeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="personal-info-facebook-name"
                    v-model="userData['facebook_name']"
                    name="personal-info-facebook-name"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('personalInfo.label.phone_number')"
              label-for="personal-info-phone-number"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('personalInfo.label.phone_number')"
                rules="required|numeric|min:10"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="personal-info-phone-number"
                    v-model="userData['phone_number']"
                    name="personal-info-phone-number"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid || isUpdating"
            >
              <b-spinner
                v-if="isUpdating"
                class="mr-50"
                small
              />
              <feather-icon
                v-else
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('personalInfo.button.update_info') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BSpinner,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BInputGroupPrepend,
  BInputGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import inputHelper from '@/mixins/utils/inputHelper'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import { required } from '@validations'

import dataRequester from '@/mixins/utils/dataRequester'
import useJwt from '@/auth/jwt/useJwt'
import userService from "@/mixins/utils/axios/user.service";

export default {
  components: {
    BSpinner,
    BAvatar,
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BInputGroupPrepend,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  props: {
    userData: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [inputHelper, dataRequester],
  data () {
    return {
      isUpdating: false,

      // validation
      required
    }
  },
  methods: {
    updatePersonalInfo () {
      const self = this
      this.isUpdating = true
      this.runApi(userService.updateUserDetail(this.userData), function (result) {
        self.$swal({
          title: self.$t('axios.title.success'),
          text: self.$te(`axios.success.${result.message}`) ? self.$t(`axios.success.${result.message}`) : result.message,
          icon: 'success',
          confirmButtonText: 'Xác nhận',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }, null, function () {
        self.isUpdating = false
      })
    }
  }
}
</script>
